"use client"

import Image from "next/image"
import {Suspense} from "react"
import {ThemeContextProvider} from "./theme-provider"
import {useSearchParams} from 'next/navigation'
import NextTopLoader from "nextjs-toploader"
import GoogleTagManager from "@/components/GoogleTagManager"

function App({children, cookies}) {
    const keyParam = useSearchParams()
    const key = keyParam.get('key')

    return (
        process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' && key !== 'arandomnewkey' ?
            <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '100vh'}}>
                <Image width={300} height={36} src="/assets/images/logo.svg" title="OysterVPN" alt="OysterVPN"
                       className="img-fluid"/>
                <br/>
                <h3 className='text-center'>We're down for maintenance, <br/>Be right back!</h3>
            </div>
            :
            <>
                <Suspense fallback={<div></div>}>
                    <GoogleTagManager/>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <ThemeContextProvider cookiesData={cookies}>
                        <NextTopLoader/>
                        {children}
                    </ThemeContextProvider>
                </Suspense>
            </>
    )
}

export default App